import React from "react";
import { AccentColor, PrimaryColor } from "../../config/colors";
import { IButtonProps } from "./interface";

const Button: React.FC<IButtonProps> = ({
  text,
  onClick,
  bgColor = PrimaryColor, // default background color
  textColor = "text-white", // default text color
  hoverColor = PrimaryColor, // default hover color
  additionalClassNames = "",
  icon,
  type = "button",
  disabled = false,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`inline-flex items-center ${textColor} border-0 py-2 px-5 focus:outline-none rounded text-base mt-4 md:mt-0 ${additionalClassNames}`}
      style={{
        backgroundColor: disabled ? "#616569" : bgColor,
        transition: "background-color 0.3s ease, transform 0.3s ease",
      }}
      onMouseEnter={(e) => {
        (e.currentTarget as HTMLButtonElement).style.backgroundColor =
          hoverColor;
        (e.currentTarget as HTMLButtonElement).style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        (e.currentTarget as HTMLButtonElement).style.backgroundColor = bgColor;
        (e.currentTarget as HTMLButtonElement).style.transform = "scale(1)";
      }}
    >
      {text}
      {icon && <span className="ml-1">{icon}</span>}
    </button>
  );
};

export default Button;
