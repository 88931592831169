import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import { baseApiUrl } from "../../utils/constants";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state added here

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Reset errors and messages
    setErrors({});
    setSuccessMessage("");
    setErrorMessage("");

    // Validate inputs
    const newErrors: { [key: string]: string } = {};

    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!message.trim()) {
      newErrors.message = "Message is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Prepare data
    const data = {
      name,
      email,
      message,
      platform: 1,
    };

    // Set loading to true before starting the submission
    setLoading(true);

    try {
      const response = await fetch(
        `${baseApiUrl}api/services/app/ContactUs/Create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setSuccessMessage("Your message has been sent successfully!");
        // Reset form
        setName("");
        setEmail("");
        setMessage("");
      } else {
        // Handle error response
        const errorData = await response.json();
        setErrorMessage(
          "An error occurred: " +
            (errorData.message || "Please try again later.")
        );
      }
    } catch (error: any) {
      setErrorMessage("An error occurred: " + error.message);
    } finally {
      // Set loading to false after submission is done
      setLoading(false);
    }
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 pt-5 mx-auto">
        <div className="flex flex-col text-center w-full mb-4">
          <h1 className="sm:text-4xl text-3xl title-font mb-4 text-gray-900 animate-fadeInDown">
            Get in Touch with Us
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base animate-fadeInUp">
            Have questions or need support? Fill out the form below, and we’ll
            be in touch soon.
          </p>
        </div>

        {successMessage && (
          <p className="text-primaryColor text-center mb-4">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-red-500 text-center mb-4">{errorMessage}</p>
        )}

        <div className="lg:w-2/2 md:w-2/3 mx-auto mb-12">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2 animate-fadeInLeft">
                <div className="relative">
                  <label className="leading-7 text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 
                    focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 
                    leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                  )}
                </div>
              </div>
              <div className="p-2 w-1/2 animate-fadeInRight">
                <div className="relative">
                  <label className="leading-7 text-sm text-gray-600">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 
                    focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 
                    leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                  )}
                </div>
              </div>
              <div className="p-2 w-full animate-fadeInUp">
                <div className="relative">
                  <label className="leading-7 text-sm text-gray-600">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 
                    focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 
                    px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                  {errors.message && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="p-2 w-full animate-fadeInUp">
                <Button
                  text={loading ? "Submitting..." : "Submit"}
                  type="submit"
                  disabled={loading} // Disable the button when loading
                />
              </div>
            </div>
          </form>
        </div>

        {/* ... rest of your component ... */}
      </div>
    </section>
  );
};

export default ContactUsPage;
