import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../button";
import logo from "../../assets/images/logo.jpg";
import { IHeaderProps } from "./interface";

const Header = (_props: IHeaderProps) => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const location = useLocation();

  const toggleMenuClick = () => {
    setIsMenuClicked(!isMenuClicked);
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className="body-font p-0">
      <div className="mx-auto flex p-0 items-center justify-between pl-0 pr-5">
        {/* Logo anchored left */}
        <Link to="/" className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="w-[150px] md:w-[200px]"
            style={{ objectFit: "cover" }}
          />
        </Link>

        <nav className="flex items-center space-x-4">
          {/* Menu option to the left of the contact button on desktop */}
          <Link
            className={`flex items-center py-2 ${
              isActive("/")
                ? "text-primaryColor font-bold"
                : "hover:text-primaryColor"
            }`}
            to="/"
            onClick={toggleMenuClick}
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className={`w-6 h-6 mr-2 transition-transform duration-300 ${
                isMenuClicked ? "rotate-90" : "rotate-0"
              }`}
              viewBox="0 0 24 24"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
            Menu
          </Link>

          {/* Hide Contact Us button on mobile and align it to the right on desktop */}
          <Link to="/contact-us" className="hidden md:block ml-auto">
            <Button text="Contact Us" onClick={() => {}} />
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
