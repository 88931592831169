import PageTitle from "../../components/pageTitle";

const TermsOfUsePage = () => {
  return (
    <div className="container mx-auto">
      <PageTitle text="Terms of use" />
      <p className="mb-4 mt-10">
        <strong>Effective date: 20 August 2024</strong>
      </p>

      <p className="mb-4">
        Welcome to the website, applications and services provided by Matadigits
        (Pty) Ltd, a digital analytics company owned by Matateni Investments
        (Pty) Ltd. These terms govern your access and use of the platform or
        site. Please review the terms, and note that by using the platform, you
        agree to its terms. If you do not agree with the terms, you may not
        access or use Matadigits’s website. You are invited to submit articles,
        sign up to receive newsletters and RSS feeds, and visit the platform or
        site in accordance with these Terms of Use.
      </p>

      <p className="mb-4">
        These Terms of Use, together with any documents, expressly incorporate
        by reference, govern your access to and use of the sites on the
        platform, whether accessed via web browser, mobile device, non-browser
        application, or e-mail, and to our paid subscription service.
      </p>

      <p className="mb-4">
        “You” and “your” refer to you as the user of the sites. “Matadigits,”
        “we,” “us” and “our” refer to Matadigits (Pty) Ltd and our successors,
        partners, affiliates, subsidiaries, and assigns.
      </p>

      <p className="mb-4">
        By using the platform or site, you expressly accept and agree to be
        bound and abide by these Terms of Use, and our Privacy Policy,
        incorporated herein by reference. If you do not agree to be bound by
        these Terms of Use, you may not access or use the platform or site.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Changes to the Terms of Use
      </h2>
      <p className="mb-4">
        Matadigits reserves the right to modify these Terms of Use at any time
        and will publish notifications of material modifications in our sole
        discretion. All changes are effective immediately when we post them and
        apply to all access to and use of the site thereafter.
      </p>

      <p className="mb-4">
        By continuing to access the sites after notice of such modifications
        have been published, you signify your agreement to be bound by them. You
        are expected to check this page from time to time so you are aware of
        any changes, as they are binding on you.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Accessing the website and Account Security
      </h2>
      <p className="mb-4">
        We reserve the right to withdraw or amend the platform or site, and any
        service or material we provide on the platform or site, in our sole
        discretion without notice.
      </p>

      <p className="mb-4">
        We will not be liable if for any reason all or any part of the platform
        or site are unavailable at any time or for any period. From time to
        time, we may restrict access to some parts of the site, or the entirety
        of the site, to users.
      </p>

      <p className="mb-4">
        You are responsible for making all arrangements necessary for you to
        have access to the site and ensuring that all persons who access the
        site through your Internet connection comply with these Terms of Use. To
        access the platform or site or some of the resources it offers, you may
        be asked to provide certain details or other information to become a
        registered user ("Registered User").
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Charges for Services
      </h2>
      <p className="mb-4">
        We may charge for access to portions of the sites or to the site as a
        whole, and we reserve the right at any time to change the amount we
        charge for such access or subscription that include authorization to
        access the platform or site. Therefore, your access to some of the
        platform’s or site’s content, features, and services may be limited
        depending on whether you have purchased a subscription.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Intellectual Property Rights
      </h2>
      <p className="mb-4">
        This website is a digital platform, and all of its contents, features,
        and functionality (including but not limited to all information,
        software, text, displays, images, video and audio, and the design,
        selection and arrangement thereof), is owned by Matadigits, its holding
        company Matateni Investments (Pty) Ltd, its licensors, or other
        providers of such material and are protected by the Republic of South
        Africa’s and relevant international copyright, trademark, patent, trade
        secret, and other intellectual property or proprietary rights laws.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">Trademarks</h2>
      <p className="mb-4">
        Matadigits and all related names, logos, product and service names,
        designs, and slogans are trademarks of Matateni Investments (Pty) Ltd.
        You must not use such marks without our prior written permission. All
        other names, logos, product and service names, designs, and slogans on
        the sites are the trademarks of their respective owners.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Linking to Website and Social Media Features
      </h2>
      <p className="mb-4">
        You may link to our homepage or other pages on the sites provided you do
        so in a way that is fair and legal and does not damage or take advantage
        of our reputation, but you must not establish a link in a way that
        suggests any form of association, approval, or endorsement on our part.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Prohibited Uses of the website
      </h2>
      <p className="mb-4">
        You may use the platform or site only for lawful purposes and in
        accordance with these Terms of Use. You agree not to use the platform or
        site:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          In any way that violates any applicable republic, provincial, local,
          or international law or regulation.
        </li>
        <li>
          For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information, or otherwise.
        </li>
        <li>
          To send, knowingly receive, upload, download, use, or re-use any
          material which does not comply with the Content Standards set out in
          these Terms of Use.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising, promotional,
          or otherwise unsolicited material, including but not limited to any
          "junk mail", "chain letter," "spam," or any other similar
          solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate Matadigits, the platform’s
          employee(s), another user, or any other person or entity (including,
          without limitation, by using their e-mail addresses or screen names).
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone's use
          or enjoyment of the sites, or which, as determined by us, may harm
          Matadigits or users of the sites or expose them to liability.
        </li>
        <li>
          To monitor, scrape, index, or otherwise copy any of the material on
          the sites by means of any robot, spider, or other automatic device,
          process, or means, regardless of whether such use may be considered a
          fair use under Republic of South Africa’s copyright or Intellectual
          Property law.
        </li>
      </ul>
      <p className="mb-4">
        Additionally, you agree not to interfere with or attempt to interfere
        with the proper working of the platform or site, including but not
        limited to:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Use the platform or site in a manner that could disable, overburden,
          damage, or impair the platform or site or interfere with any other
          party's use of the site.
        </li>
        <li>
          Use any device, software, or routine that interferes with the proper
          working of the platform or site; introducing any viruses, Trojan
          horses, worms, logic bombs, or other material which is malicious or
          technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any part of the platform or site, the server on which the
          sites are stored, or any server, computer, or database connected to
          the platform or site.
        </li>
        <li>
          Attack the platform or site via a denial-of-service attack or a
          distributed denial-of-service attack.
        </li>
      </ul>
      <p className="mb-4">
        Engaging in a prohibited use of the platform or site may result in
        civil, criminal, and/or administrative penalties, fines, or sanctions
        against the user and those assisting the user.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        User Contributions
      </h2>
      <ul className="list-disc ml-6 mb-4">
        <li>
          The platform or site may contain features that allow users to post,
          submit, publish, display, or transmit to other users or other persons
          content or materials (collectively, "User Contributions") on or
          through the platform or site.
        </li>
        <li>
          All User Contributions must comply with the Content Standards set out
          in these Terms of Use.
        </li>
        <li>
          Any User Contribution you post to the platform or site will be
          considered non-confidential and non-proprietary. By posting any User
          Contribution on the platform or site, you grant us and our affiliates
          and service providers, and each of their and our respective licensees,
          successors, and assigns a perpetual, royalty-free, and irrevocable
          right and license to use, reproduce, modify, adapt, publish,
          translate, distribute, transmit, publicly display, publicly perform,
          sublicense, create derivative works from, transfer, and sell any such
          User Contribution.
        </li>
        <li>
          You represent and warrant that you own or control all rights in and to
          the User Contributions, including the right to license such User
          Contributions, and that all of your User Contributions do and will
          comply with these Terms of Use.
        </li>
        <li>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not Matadigits, have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness.
        </li>
        <li>
          We are not responsible, or liable to any third party, for the content
          or accuracy of any User Contributions posted by you or any other user
          of the platform or site.
        </li>
      </ul>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Disclosure, Monitoring, and Enforcement of User Contributions
      </h2>
      <p className="mb-4">We have the right to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Remove or refuse to post any User Contributions for any or no reason
          in our sole discretion.
        </li>
        <li>
          Take any action with respect to any User Contribution that we deem
          necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates the Terms of Use,
          infringes any intellectual property right or other right of any person
          or entity, threatens the personal safety of users of the sites or the
          public, or could create liability for the Company.
        </li>
        <li>
          Disclose your identity or other information about you to any third
          party who claims that material posted by you violates their rights,
          including their intellectual property rights or their right to
          privacy.
        </li>
        <li>
          Take appropriate legal action, including without limitation, referral
          to law enforcement, for any illegal or unauthorised use of the
          platform or site.
        </li>
        <li>
          Terminate or suspend your access to all or part of the platform or
          site for any or no reason, including without limitation, any violation
          of these Terms of Use.
        </li>
        <li>
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the platform or site.
        </li>
        <li>
          YOU WAIVE AND HOLD HARMLESS Matadigits AND ITS AFFILIATES, LICENSEES
          AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN
          BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
          INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
          INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </li>
        <li>
          However, we cannot and do not undertake to review material before it
          is posted on the platform or sites and cannot ensure prompt removal of
          objectionable material after it has been posted. Accordingly, we
          assume no liability for any action or inaction regarding
          transmissions, communications, or content provided by any user or
          third party. We have no liability or responsibility to anyone for
          performance or non-performance of the activities described in this
          section.
        </li>
      </ul>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Content Standards for User Contributions
      </h2>
      <p className="mb-4">
        These Content Standards apply to any and all User Contributions. You are
        responsible for your own User Contributions, and you agree that, to the
        best of your knowledge, your User Contributions will be truthful and
        accurate. Do not make User Contributions that involve the following:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Securities for sale, barter, or exchange</li>
        <li>
          Harassment, defamation, threats, stalking, bullying, or violations of
          the legal rights of others
        </li>
        <li>Illegal activities</li>
        <li>
          Impersonation of any person or entity, or falsely state or
          misrepresent your affiliation with a person or entity
        </li>
        <li>
          Infringement of another's intellectual property of any type,
          including, but not limited to, trade secrets, trademarks, and
          copyrights
        </li>
        <li>
          Obscene, vulgar, bigoted, hateful, or racially offensive language or
          images
        </li>
        <li>Commercial advertising</li>
        <li>
          Gambling, contests, chain letters, "pyramid schemes," or "multi-level
          marketing" schemes
        </li>
        <li>
          Violations of any applicable local, state, national, or international
          law in connection with your use of the Sites, including securities
          laws and regulations.
        </li>
        <li>Personally attacking the author of a post or other users</li>
      </ul>
      <p>
        Furthermore, you agree that, if a third party claims that any User
        Contribution you have contributed to the sites is unlawful, you will
        bear the full burden of establishing that the User Contribution complies
        with all applicable laws.
      </p>
      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        How to Report Violations
      </h2>
      <p className="mb-4">
        If you believe that any content on the sites violates the Terms of Use,
        please notify the Matadigits by sending an email to{" "}
        <a href="mailto:support@matadigits.xyz" className="text-primaryColor">
          support@matadigits.xyz
        </a>
      </p>
      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Do Not Rely on Information Posted
      </h2>
      <p className="mb-4">
        The content of the platform is offered for general information,
        discussion, education, empowerment and entertainment purposes only, but
        this does not absolve the reader from undertaking further research to
        ascertain the veracity of the content.
      </p>
      <p className="mb-4">
        Some of the content is unmoderated and reflects the personal opinions of
        writers, contributors, and users. You should always be skeptical about
        any information on the platform, because the information may be
        offensive, harmful, and/or wrong.
      </p>
      <p className="mb-4">
        Neither the content of the platform, nor the links to other websites
        contained therein, are routinely moderated, screened, approved,
        reviewed, or endorsed by Matadigits or any affiliated person or entity.
      </p>
      <p className="mb-4">
        We do not warrant the accuracy, completeness, or usefulness of the
        information on the platform. Any reliance you place on such information
        is strictly at your own risk. We disclaim all liability and
        responsibility arising from any reliance placed on such materials by you
        or any other visitor to the platform, or by anyone who may be informed
        of any of its contents.
      </p>
      <p className="mb-4">
        The platform may include content provided by third parties, including
        materials provided by other users, bloggers, and third-party licensors,
        syndicators, aggregators, and/or reporting services. All statements
        and/or opinions expressed in these materials, and all articles and
        responses to questions and other content, other than the content
        provided by Matadigits, are solely the opinions and the responsibility
        of the person or entity providing those materials. Such materials do not
        necessarily reflect the opinion of Matadigits. We are not responsible,
        or liable to you or any third party, for the content or accuracy of any
        materials provided by any third parties.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Age and User Contribution Removal
      </h2>
      <p className="mb-4">
        Matadigits is not intended for children under 18 years of age. If you
        are under 18, do not use or provide any information on the sites or on
        or through any of its features, register on the platform, use any of the
        interactive or public comment features of the platform, or provide any
        information about yourself to us.
      </p>
      <p className="mb-4">
        See our Privacy Policy for more information about contacting Matadigits
        if you believe we have collected information from a child under 18.
        However, Matadigits allows minors under the age of 18 years old to
        access, or become Registered Users of, the platform.
      </p>
      <p className="mb-4">
        Notwithstanding this, if you are a minor under the age of 18 years old
        and a Registered User, you may request removal of your User
        Contributions posted on the platform.
      </p>
      <p>
        To request removal of a User Contribution, you must notify us at{" "}
        <a href="mailto:support@matadigits.xyz" className="text-primaryColor">
          support@matadigits.xyz
        </a>
        .
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Disclaimer and Limitation on Liability
      </h2>
      <p className="mb-4">
        The platform and any information, products, or services therein are
        provided on an “as is” and “as available” basis, without any warranties
        of any kind, either express or implied. Matadigits hereby disclaims all
        warranties of any kind, whether express or implied, statutory or
        otherwise, including without limitation, the implied warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
      </p>
      <p className="mb-4">
        Matadigits does not warrant that the platform will operate in an
        uninterrupted or error-free manner or that the platform is free of
        viruses or other harmful components. Use of the platform or information
        obtained from or through the platform is at your own risk.
      </p>
      <p className="mb-4">
        In no event will Matadigits, its affiliates or their licensors, service
        providers, employees, agents, officers, or directors be liable for any
        loss or damages, under any legal theory, arising out of or in connection
        with your use, or inability to use, the platform, any websites linked to
        the platform, any content on the platform or such other websites or any
        services obtained through the platform or such other websites, including
        any direct, indirect, special, incidental, consequential, or punitive
        damages, including but not limited to, personal injury, pain and
        suffering, emotional distress, loss of revenue, loss of profits, loss of
        business or anticipated savings, loss of use, loss of goodwill, loss of
        data, and whether caused by tort (including negligence), breach of
        contract or otherwise, even if foreseeable.
      </p>
      <p>
        Neither Matadigits nor the platform are an intermediary, broker/ dealer,
        investment advisor, or exchange, and neither provides services as such.
      </p>
      <p>
        Matadigits is not responsible for any actions or inaction on your part
        based on the information in the platform.
      </p>
      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Choice of Law and Forum
      </h2>
      <p className="mb-4">
        You agree that any dispute arising out of or relating to the platform,
        these Terms of Use, or any content posted to the platform, including
        copies and republication thereof, whether based in contract, tort,
        statutory, or other law, will be governed by the laws of the Republic of
        South Africa. You further consent to the personal jurisdiction of and
        exclusive venue in the Republic of South Africa as the legal forum for
        any such dispute.
      </p>
      <h2 className="text-xl mt-8 mb-4 text-primaryColor">Copyright Policy</h2>
      <p className="mb-4">
        Matadigits is governed by the general legal prescripts on copyright and
        Intellectual Property of the Republic of South Africa and available in
        the international community, as well as specific guidelines of the World
        Intellectual Property Organisation (WIPO)(
        <a
          href="https://wipo.int"
          target="_blank"
          className="text-primaryColor"
          rel="noreferrer"
        >
          www.wipo.int
        </a>
        ).
      </p>
      <p className="mb-4">
        If you believe your copyrights are being infringed, please write to us
        to register your concern via{" "}
        <a href="mailto:support@matadigits.xyz" className="text-primaryColor">
          support@matadigits.xyz
        </a>
        , indicating the following:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed.
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works are covered by a single
          notification, a representative list of such works at the platform.
        </li>
        <li>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit the service provider to locate the material.
        </li>
        <li>
          Information reasonably sufficient to permit the service provider to
          contact the complaining party such as an address, telephone number,
          and if available, an electronic mail address at which the complaining
          party may be contacted.
        </li>
        <li>
          A statement that the complaining party has a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law.
        </li>
        <li>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </li>
      </ul>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify, defend, and hold harmless Matadigits from any
        claims, losses, or damages, including legal fees, resulting from your
        violation of these Terms of Use, your use of the platform or your
        placement of any content onto the platform, and to fully cooperate in
        Matadigits’s defense against any such claims.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">
        Waiver and Severability
      </h2>
      <p className="mb-4">
        No waiver by us of any term or condition set forth in these Terms of Use
        shall be deemed a further or continuing waiver of such term or condition
        or a waiver of any other term or condition, and our failure to assert a
        right or provision under these Terms of Use shall not constitute a
        waiver of such right or provision.
      </p>
      <p className="mb-4">
        If any provision of these Terms of Use is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of
        these Terms of Use will continue in full force and effect.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">Integration</h2>
      <p className="mb-4">
        These Terms of Use constitute the entire agreement between you and
        Matadigits with respect to the subject matter herein and supersedes any
        and all prior or contemporaneous oral or written agreements.
      </p>

      <h2 className="text-xl mt-8 mb-4 text-primaryColor">Contact Us</h2>
      <p className="mb-4">
        If you have any feedback, comments, or questions relating to these Terms
        of Use or the platform, please e-mail questions to{" "}
        <a href="mailto:support@matadigits.xyz" className="text-primaryColor">
          support@matadigits.xyz
        </a>
        .
      </p>
    </div>
  );
};

export default TermsOfUsePage;
