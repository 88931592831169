import React, { useEffect } from "react";
import PageTitle from "../../components/pageTitle";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto">
      <PageTitle text="Privacy Policy" />

      <section className="mb-6 mt-10">
        <h2 className="text-xl text-primaryColor mb-2">
          Personal information we collect
        </h2>
        <ul className="list-disc ml-5">
          <li>
            <strong>Information you provide to us.</strong> Personal information
            you may provide to us through the website or otherwise includes:
          </li>
          <li>
            <strong>Contact data</strong>, such as your first and last name,
            email address, and company name.
          </li>
          <li>
            <strong>Communications data</strong>, based on our exchanges with
            you, including when you contact us through the website, social
            media, or otherwise.
          </li>
          <li>
            <strong>Marketing data</strong>, such as your preferences for
            receiving our marketing communications and details about your
            engagement with them.
          </li>
          <li>
            <strong>Job application data</strong>, such as information you
            submit in connection with an application for a job (including
            information in a CV, resume or otherwise collected through the job
            application process).
          </li>
          <li>
            <strong>Other data</strong> not specifically listed here, which we
            will use as described in this Privacy Policy or as otherwise
            disclosed at the time of collection.
          </li>
        </ul>
        <br></br>
        <p>
          <strong>Third-party sources.</strong> We may combine personal
          information we receive from you with personal information we obtain
          from other sources, such as social media platforms and other publicly
          available sources.
        </p>
        <br></br>
        <p>
          <strong>Automatic data collection.</strong> We, our service providers,
          and our business partners may automatically log information about you,
          your computer or mobile device, and your interaction over time with
          the website, our communications and other online services, such as:
        </p>
        <ul className="list-disc ml-5">
          <li>
            Device data, such as your computer or mobile device’s operating
            system type and version, manufacturer and model, browser type,
            screen resolution, RAM and disk size, CPU usage, device type (e.g.,
            phone, tablet), IP address, unique identifiers (including
            identifiers used for advertising purposes), language settings,
            mobile device carrier, radio/network information (e.g., Wi-Fi, LTE,
            3G), and general location information such as city, state or
            geographic area.
          </li>
          <li>
            Online activity data, such as pages or screens you viewed, how long
            you spent on a page or screen, the website you visited before
            browsing to the website, navigation paths between pages or screens,
            information about your activity on a page or screen, access times
            and duration of access, and whether you have opened our emails or
            clicked links within them.
          </li>
        </ul>
        <br></br>
        <p>
          <strong>Cookies and similar technologies.</strong> Some of the
          automatic collection described above is facilitated by the following
          technologies:
        </p>
        <ul className="list-disc ml-5">
          <li>
            Cookies, which are small text files that websites store on user
            devices and that allow web servers to record users’ web browsing
            activities and remember their submissions, preferences, and login
            status as they navigate a site. Cookies used on our sites include
            both “session cookies” that are deleted when a session ends,
            “persistent cookies” that remain longer, “first party” cookies that
            we place and “third party” cookies that our third-party business
            partners and service providers place.
          </li>
          <li>
            Local storage technologies, like HTML5, that provide
            cookie-equivalent functionality but can store larger amounts of data
            on your device outside of your browser in connection with specific
            applications.
          </li>
          <li>
            Web beacons, also known as pixel tags or clear GIFs, which are used
            to demonstrate that a webpage or email was accessed or opened, or
            that certain content was viewed or clicked.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">
          How we use your personal information
        </h2>
        <p>
          We may use your personal information for the following purposes or as
          otherwise described at the time of collection:
        </p>
        <ul className="list-disc ml-5">
          <li>
            <strong>Service delivery and business operations.</strong> We may
            use your personal information to:
            <ul className="list-disc ml-5">
              <li>
                provide, operate and improve the website and our business;
              </li>
              <li>
                communicate with you about the website, including by sending
                announcements, updates, security alerts, and support and
                administrative messages;
              </li>
              <li>
                communicate with you about events or contests in which you
                participate;
              </li>
              <li>
                understand your needs and interests, and personalise your
                experience with the website and our communications;
              </li>
              <li>
                provide support for the website, and respond to your requests,
                questions and feedback.
              </li>
            </ul>
          </li>
          <li>
            <strong>Research and development.</strong> We may use your personal
            information for research and development purposes, including to
            analyse and improve the website and our business.
          </li>
          <li>
            <strong>Marketing and advertising.</strong> We, our service
            providers and our third-party advertising partners may collect and
            use your personal information for marketing and advertising
            purposes:
            <ul className="list-disc ml-5">
              <li>
                Direct marketing. We may send you direct marketing
                communications. You may opt-out of our marketing communications
                as described in the opt-out of marketing section below.
              </li>
              <li>
                Interest-based advertising. Our third-party advertising partners
                may use cookies and similar technologies to collect information
                about your interaction (including the data described in the
                automatic data collection section above) with the website, our
                communications and other online services over time, and use that
                information to serve online ads that they think will interest
                you. This is called interest-based advertising. We may also
                share information about our users with these companies to
                facilitate interest-based advertising to those or similar users
                on other online platforms.
              </li>
            </ul>
          </li>
          <li>
            <strong>
              To manage our recruiting and process job applications.
            </strong>{" "}
            We may use personal information, such as information submitted to us
            in a job application, to facilitate our recruitment activities and
            process job applications.
          </li>
          <li>
            <strong>Compliance and protection.</strong> We may use your personal
            information to:
            <ul className="list-disc ml-5">
              <li>
                comply with applicable laws, lawful requests, and legal process;
              </li>
              <li>
                protect our, your or others’ rights, privacy, safety or
                property;
              </li>
              <li>
                audit our internal processes for compliance with legal and
                contractual requirements or our internal policies;
              </li>
              <li>enforce the terms and conditions that govern the Site;</li>
              <li>
                prevent, identify, investigate and deter fraudulent, harmful,
                unauthorized, unethical or illegal activity, including
                cyberattacks and identity theft.
              </li>
            </ul>
          </li>
          <li>
            <strong>With your consent.</strong> In some cases, we may
            specifically ask for your consent to collect, use or share your
            personal information, such as when required by law.
          </li>
          <li>
            <strong>
              To create aggregated, de-identified and/or anonymised data.
            </strong>{" "}
            We may create aggregated, de-identified and/or anonymised data from
            your personal information and other individuals whose personal
            information we collect. We may use this aggregated, de-identified
            and/or anonymised data and share it with third parties for our
            lawful business purposes.
          </li>
          <li>
            <strong>Cookies and similar technologies.</strong> In addition to
            the other uses included in this section, we may use the Cookies and
            similar technologies described above for the following purposes:
            <ul className="list-disc ml-5">
              <li>
                Technical operation. To allow the technical operation of the
                website.
              </li>
              <li>
                Functionality. To enhance the performance and functionality of
                our services.
              </li>
              <li>
                Advertising. To help our third-party advertising partners
                collect information about how you use the website and other
                online services over time.
              </li>
              <li>
                Analytics. To help us understand user activity on the website,
                including which pages are most and least visited and how
                visitors move around the website as well as user interactions
                with our emails. For example, we use Google Analytics for this
                purpose. You can learn more about Google Analytics and how to
                prevent the use of Google Analytics relating to your use of our
                sites here:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noreferrer"
                >
                  tools.google.com/dlpage/gaoptout
                </a>
                .
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">
          How we share your personal information
        </h2>
        <p>
          We may share your personal information with the following parties and
          as otherwise described in this Privacy Policy or at the time of
          collection:
        </p>
        <ul className="list-disc ml-5">
          <li>
            <strong>Service providers.</strong> Third parties that provide
            services on our behalf or help us operate the website or our
            business.
          </li>
          <li>
            <strong>Business and marketing partners.</strong> Third parties with
            whom we co-sponsor events or promotions, with whom we jointly offer
            products or services, or whose products or services may be of
            interest to you.
          </li>
          <li>
            <strong>Professional advisors.</strong> Professional advisors, such
            as lawyers, auditors, bankers and insurers, where necessary in the
            course of the professional services that they render to us.
          </li>
          <li>
            <strong>Authorities and others.</strong> Law enforcement, government
            authorities, and private parties, as we believe in good faith to be
            necessary or appropriate for the compliance and protection purposes
            described above.
          </li>
          <li>
            <strong>Business transferees.</strong> Acquirers and other relevant
            participants in business transactions (or negotiations of or due
            diligence for such transactions) involving a corporate divestiture,
            merger, consolidation, acquisition, reorganisation, sale or other
            disposition of all or any portion of the business or assets of, or
            equity interests in, our or our affiliates (including, in connection
            with a bankruptcy or similar proceedings).
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">Your choices</h2>
        <p>
          In this section, we describe the rights and choices available to all
          users.
        </p>
        <br></br>
        <p>
          <strong>Opt-out of marketing communications.</strong> You may opt-out
          of marketing-related emails by following the opt-out or unsubscribe
          instructions at the bottom of the email, or by contacting us. Please
          note that if you choose to opt-out of marketing-related emails, you
          may continue to receive service-related and other non-marketing
          emails.
        </p>
        <br></br>
        <p>
          <strong>Cookies.</strong> Most browsers let you remove or reject
          cookies. To do this, follow the instructions in your browser settings.
          Many browsers accept cookies by default until you change your
          settings. Please note that if you set your browser to disable cookies,
          the website may not work properly. For more information about cookies,
          including how to see what cookies have been set on your browser and
          how to manage and delete them, visit www.allaboutcookies.org.
        </p>
        <br></br>
        <p>
          <strong>Advertising choices.</strong> You may be able to limit use of
          your information for interest-based advertising through the following
          settings/ options/tools:
          <ul className="list-disc ml-5">
            <li>
              <strong>Browser settings.</strong> Changing your internet web
              browser settings to block third-party cookies.
            </li>
            <li>
              <strong>Privacy browsers/plug-ins.</strong> Using privacy browsers
              and/or ad-blocking browser plug-ins that let you block tracking
              technologies.
            </li>
            <li>
              <strong>Platform settings.</strong> Google and Facebook offer
              opt-out features that let you opt-out of use of your information
              for interest-based advertising. You may be able to exercise that
              option at the following websites:
              <ul className="list-disc ml-5">
                <li>
                  <a
                    href="https://adssettings.google.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google
                  </a>
                </li>
                <li>
                  <a
                    href="https://facebook.com/about/ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    X
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/psettings/guest-controls/retargeting-opt-out "
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <strong>Ad industry tools.</strong> Opting out of interest-based
              ads from companies that participate in the following industry
              opt-out programs:
              <ul className="list-disc ml-5">
                <li>
                  <a
                    href="https://thenai.org/opt-out"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Network Advertising Initiative
                  </a>
                </li>
                <li>
                  <a
                    href="https://optout.aboutads.info/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Digital Advertising Alliance
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <br></br>
        <p>
          You will need to apply these opt-out settings on each device and
          browser from which you wish to limit the use of your information for
          interest-based advertising purposes.
        </p>
        <br></br>
        <p>
          We cannot offer any assurances as to whether the companies we work
          with participate in the opt-out programs described above.
        </p>
        <br></br>
        <p>
          <strong>Do Not Track.</strong> Some Internet browsers may be
          configured to send “Do Not Track” signals to the online services that
          you visit. We currently do not respond to “Do Not Track” or similar
          signals. To find out more about “Do Not Track,” please visit &nbsp;
          <a href="https://.allaboutdnt.com" target="_blank" rel="noreferrer">
            www.allaboutdnt.com
          </a>
        </p>
        <br></br>
        <p>
          Declining to provide information. We need to collect personal
          information to provide certain services. If you do not provide the
          information we identify as required or mandatory, we may not be able
          to provide those services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">
          Other sites and services
        </h2>
        <p>
          The website may contain links to websites and other online services
          operated by third parties. In addition, our content may be integrated
          into web pages or other online services that are not associated with
          us.
        </p>
        <br></br>
        <p>
          These links and integrations are not an endorsement of, or
          representation that we are affiliated with, any third party. We do not
          control websites or online services operated by third parties, and we
          are not responsible for their actions. We encourage you to read the
          privacy policies of the other websites and online services you use.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">Security</h2>
        <p>
          We employ a number of technical, organisational and physical
          safeguards designed to protect the personal information we collect.
          However, security risk is inherent in all internet and information
          technologies and we cannot guarantee the security of your personal
          information. International data transfer We are headquartered in South
          Africa and may use service providers that operate in other countries.
          Your personal information may be transferred to the South Africa or
          other locations where privacy laws may not be as protective as those
          in your state, province, or country.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">Children</h2>
        <p>
          The website is not intended for use by anyone under 18 years of age.
          If you are a parent or guardian of a child from whom you believe we
          have collected personal information in a manner prohibited by law,
          please contact us.
        </p>
        <br></br>
        <p>
          If we learn that we have collected personal information through the
          website from a child without the consent of the child’s parent or
          guardian as required by law, we will comply with applicable legal
          requirements to delete the information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl text-primaryColor mb-2">
          Changes to this Privacy Policy
        </h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time. If we
          make material changes to this Privacy Policy, we will notify you by
          updating the date of this Privacy Policy and posting it on the Site or
          other appropriate means. Any modifications to this Privacy Policy will
          be effective upon our posting the modified version (or as otherwise
          indicated at the time of posting). In all cases, your use of the
          website after the effective date of any modified Privacy Policy
          indicates your acknowledgment that the modified Privacy Policy applies
          to your interactions with the website and our business.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl mb-2  text-primaryColor">Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          practices, please contact us at:
        </p>
        <address className="mt-2">
          <p>
            Email:{" "}
            <a
              href="mailto:support@matadigits.xyz"
              className="text-primaryColor"
            >
              support@matadigits.xyz
            </a>
          </p>
        </address>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
