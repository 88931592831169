import { useState, useEffect } from "react";
import { baseApiUrl } from "./constants";
import IArticle from "../interfaces/article";
import axios from "axios";

const useArticleList = (articleType?: number) => {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [pageSize] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items

  const fetchArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseApiUrl}api/services/app/Article/GetAll`,
        {
          params: {
            Keyword: searchKeyword,
            type: articleType,
            MaxResultCount: pageSize,
            SkipCount: (currentPage - 1) * pageSize,
          },
        }
      );
      const newArticles = response.data.result.items;

      // Use array filtering instead of Set to avoid duplicates
      setArticles((prevArticles) => {
        const uniqueArticles = [
          ...prevArticles,
          ...newArticles.filter(
            (newArticle: IArticle) =>
              !prevArticles.some((a) => a.id === newArticle.id)
          ),
        ];
        return uniqueArticles;
      });
      setTotalItems(response.data.result.totalCount);
    } catch (error) {
      console.error("Error fetching articles", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [currentPage]); // Fetch articles when search keyword or current page changes

  return {
    articles,
    loading,
    searchKeyword,
    setSearchKeyword,
    currentPage,
    setCurrentPage,
    pageSize,
    totalItems,
  };
};

export default useArticleList;
