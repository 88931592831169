import IPartner from "../interfaces/partner";

const partnersList: Array<IPartner> = [
  {
    name: "Twilio Segment",
    url: "https://segment.com/",
  },
  {
    name: "LiveRamp",
    url: "https://liveramp.com/",
  },
  {
    name: "Telesign",
    url: "https://www.telesign.com/",
  },
  {
    name: "Cribl",
    url: "https://cribl.io/",
  },
  {
    name: "Alcatrazai",
    url: "https://alcatrazai.com/",
  },
  {
    name: "Coveo",
    url: "https://www.coveo.com/en/",
  },
  {
    name: "MicroStrategy",
    url: "https://www.microstrategy.com/",
  },
  {
    name: "Nimble",
    url: "https://www.nimble.com/",
  },
  {
    name: "Crowdscan",
    url: "https://www.crowdscan.be/",
  },
  {
    name: "Intellicheck",
    url: "https://www.intellicheck.com/",
  },
  {
    name: "Gainsight",
    url: "https://www.gainsight.com/",
  },
  {
    name: "Scrubby",
    url: "https://scrubby.io/",
  },
  {
    name: "Airship",
    url: "https://www.airship.com/",
  },
  {
    name: "Acceleraid",
    url: "https://acceleraid.ai/en/",
  },
  {
    name: "Flexera",
    url: "https://www.flexera.com/",
  },
  {
    name: "HERE Technologies",
    url: "https://www.here.com/",
  },
  {
    name: "Tealium",
    url: "https://tealium.com/thankyou-contact/",
  },
  {
    name: "Nagarro",
    url: "https://www.nagarro.com/en/",
  },
  {
    name: "Typeform",
    url: "https://typeform.com/",
  },
  {
    name: "Fivetran",
    url: "https://www.fivetran.com/",
  },
  {
    name: "Contentful",
    url: "https://www.contentful.com/",
  },
  {
    name: "Seon",
    url: "https://seon.io/partners/",
  },
  {
    name: "Skai",
    url: "https://skai.io/",
  },
  {
    name: "Affirm",
    url: "https://www.affirm.com/u",
  },
  {
    name: "Secuvyai",
    url: "https://secuvy.ai/",
  },
  {
    name: "Mixpanel",
    url: "https://mixpanel.com",
  },
  {
    name: "Sociallinks",
    url: "https://sociallinks.io/",
  },
  {
    name: "Visier",
    url: "https://www.visier.com/",
  },
  {
    name: "Fabric",
    url: "https://fabric.inc/",
  },
];

export { partnersList };
