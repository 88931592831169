import { useEffect, useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import useArticleList from "../../utils/useArticleList";
import ArticleGridCard from "../../components/articleGridCard";
import ArticleStackCard from "../../components/articleStackCard";

const NewsPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { articles, loading, setCurrentPage, currentPage, totalItems } =
    useArticleList(1);

  // Detect screen size to toggle between StackCard and GridCard
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile width as <= 768px
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize); // Clean up the listener
  }, []);

  // Infinite scroll to fetch more articles when reaching the bottom
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position and the height of the document
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      // Check if the user is close to the bottom of the page
      if (scrollTop + clientHeight >= scrollHeight - 200 && !loading) {
        // Load more articles if not loading and there are more articles to fetch
        if (articles.length < totalItems) {
          setCurrentPage(currentPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll); // Add scroll event listener
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup the event listener
  }, [loading, articles, totalItems, currentPage, setCurrentPage]);

  return (
    <div className="bg-white px-10">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[85vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallaxNews mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>
        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-4xl md:text-7xl text-white drop-shadow-lg mb-4 md:mb-6">
            World News
          </h1>
        </div>
        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              Stay updated with the latest stories, insights, and developments
              shaping our world. Explore what's happening today and stay
              informed.
            </p>
            <Link to="/contact-us">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-2 text-base md:text-lg text-white bg-primaryColor rounded-md shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Let's talk
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Cards Section */}
      <div className="flex flex-wrap mt-10 mx-[-1rem] md:mx-[-2rem]">
        {articles.map((section) =>
          isMobile ? (
            <ArticleGridCard key={section.id} {...section} />
          ) : (
            <ArticleStackCard key={section.id} {...section} />
          )
        )}
      </div>

      {/* Loader */}
      {loading && (
        <div className="flex justify-center items-center my-10">
          <div className="loader"></div> {/* Add your loader style here */}
        </div>
      )}
    </div>
  );
};

export default NewsPage;
