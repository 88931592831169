import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ContactUsPage from "./pages/contact-us";
import Header from "./components/header";
import Footer from "./components/footer";
import StoryDetailsPage from "./pages/stories/details";
import ResourcesPage from "./pages/resources";
import IndustriesPage from "./pages/industries";
import PrivacyPolicyPage from "./pages/privacy-policy";
import DigitalStrategyPage from "./pages/digital-strategy";
import TechnologyModernisationPage from "./pages/technology-modernisation";
import HomePage from "./pages/home";
import CompanyPage from "./pages/company";
import TermsOfUsePage from "./pages/terms-of-use";
import MenuPage from "./pages/menu";
import ResearchPage from "./pages/research";
import NewsPage from "./pages/news";

const pageTransition = {
  initial: { opacity: 0, x: -100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 100 },
};

const AnimatedRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <div>
      <AnimatePresence mode="wait">
        {location.pathname !== "/" && location.pathname !== "/home" && (
          <Header />
        )}
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <MenuPage />
              </motion.div>
            }
          />
          <Route
            path="/home"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <MenuPage />
              </motion.div>
            }
          />
          <Route
            path="/company"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <CompanyPage />
              </motion.div>
            }
          />
          <Route
            path="/services"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <ContactUsPage />
              </motion.div>
            }
          />
          <Route
            path="/contact-us"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <ContactUsPage />
              </motion.div>
            }
          />
          <Route
            path="/news"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <NewsPage />
              </motion.div>
            }
          />
          <Route
            path="/storyDetails/:id"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <StoryDetailsPage />
              </motion.div>
            }
          />
          <Route
            path="/resources"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <ResourcesPage />
              </motion.div>
            }
          />
          <Route
            path="/industries"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <IndustriesPage />
              </motion.div>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <PrivacyPolicyPage />
              </motion.div>
            }
          />
          <Route
            path="/digital-strategy"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <DigitalStrategyPage />
              </motion.div>
            }
          />
          <Route
            path="/technology-modernisation"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <TechnologyModernisationPage />
              </motion.div>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <TermsOfUsePage />
              </motion.div>
            }
          />
          <Route
            path="/research"
            element={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
              >
                <ResearchPage />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
      {location.pathname !== "/" && location.pathname !== "/home" && <Footer />}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
};

export default App;
