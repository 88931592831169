const industriesList = [
  {
    title: "Public Sector",
    description:
      "The public sector in South Africa and the rest of Sub Saharan Africa is still grappling with digitisation efforts. Government essentially runs on manual paper-based processes for most of its citizens facing services. This is notwithstanding some strides made in certain areas to automate business processes and systems. Data remains underutilised because of its inaccessibility. A great opportunity exists to unlock the value of data in the custody of government for better planning, budgeting and decision making on policy.",
  },
  {
    title: "Retail",
    description:
      "Accelerating the discovery and delivery of new value makes a compelling case in the retail sector which is competing for the shelf attention of consumers. Product owners and supermarkets alike are confronted by the ever-changing nature of market dynamics. The Gen Z buyers are forcing the change, daily. Understanding their product needs and preferences in accessing such products is a shifting occupation. Advertising platform providers known as “Outdoor” or television or radio media are challenged to use latest technologies as they enter into new, digital spaces to unlock marketing value for their customers. Data analytics combines with behavioural economics to enable a better understanding of the consumer.",
  },
  {
    title: "Telecoms",
    description:
      "Traditional telcos continue to benefit from their first-mover advantage into the market dating back to the early 1990s. Fast-forward to the end of the first quarter of the Twenty First Century, the game has changed altogether. Digital stores are the real platforms for engaging the digitally savvy customer. Retaining this type of customer in a market flux with scores of smart telco players remains a persistent challenge.",
  },
  {
    title: "Insurance",
    description:
      "The South African insurance sector is accustomed to a traditional customer who is happy with a monthly premium and thus rests assured. However, across the globe, pay-per-use insurance options are becoming second nature. Insurance players that jumped into digital spaces and correctly invested in customer analytics are ready to withstand the market changes. Similarly, the convergence of the telecoms, banking and insurance (including medical aid cover) has changed the face of the insurance service provider.",
  },
  {
    title: "Logistics",
    description:
      "South Africa is a logistics-driven economy, like the rest of other countries. The difference here is that a lot of cargo is transported by trucks on the road instead of trains. This poses a lot of strain because of cargo and road safety issues arising out of the growing traffic and managing an expansive fleet of long-haul and small trucks.",
  },
  {
    title: "Media",
    description:
      "Market segmentation and paying attention to readers, listeners, and viewers with access to a vast variety of media is challenge for traditional media. The advent of social media networks makes traditional media irrelevant, unless they also drive up presence in the new platforms. Investment in customer analytics is the wherewithal for media survival in the digital space.",
  },
  {
    title: "Energy & Petrochemical",
    description:
      "Differentiation in the energy and petrochemical sectors is key. The fuel market is effectively dominated by four players. Customer service and convenience are what makes a station distinct. So does a fresher branding presence. But it is the unceasing preoccupation with understanding the customer that matters most for profitability.",
  },
  {
    title: "Automotive",
    description:
      "The proliferation of automotive brands has shifted focus to affordability, ease-of-use, and pragmatism all over again. Brands from Asia are taking the fight onto the traditional Western brands without fear. A mix of smart and aggressive advertising is determining the success in the market.",
  },
  {
    title: "Consumer Goods",
    description:
      "Consumers are increasingly enjoying broadened access to branch choices. Loyalty is now second to accessibility and ease-of-use. This is challenging consumer brands to think deeply and invest in consumer analytics, beyond the traditional market surveys.",
  },
  {
    title: "Banking",
    description:
      "Banks are at the cutting edge of convergence.  They are facing new competition with the rise of new fintech platforms and mobile money applications. Telcos and retailers have also expanded into banking. Lending, financing and moving money within and across borders are all adding a new flavour to banking. All these seismic changes are forcing banks to go digital at a faster pace. Today banks force their upwardly mobile customers to use mobile apps — even when they are in the branch in person. The banking apps are the new dynamic branch.  Banks are launching and upgrading with ever-increasing applications and functionalities that make banking easier and seamless, aiming to retain customers with loyalty. Consequently, customer analytics have become more critical to keep track of customer behaviour and understand their banking needs.",
  },
];
export { industriesList };
