import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import "aos/dist/aos.css";
import AOS from "aos";
import mobileImage from "../../assets/images/mobile.jpg";
import beltImage from "../../assets/images/belt.jpg";
import {
  FaConnectdevelop,
  FaDatabase,
  FaMobile,
  FaRobot,
  FaUserCog,
} from "react-icons/fa";

const TechnologyModernisationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing style
      once: true, // Only animate once when scrolled into view
    });
  }, []);
  return (
    <div className="mx-auto space-y-0 px-10">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[55vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallaxTechMod mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>
        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-4xl md:text-7xl text-white drop-shadow-lg mb-4 md:mb-6">
            Technology Modernisation
          </h1>
        </div>
        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              We help you modernise your technology environment. This includes
              building apps and automating your business processes.
            </p>
            <Link to="/contact-us">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-2 text-base md:text-lg text-white bg-primaryColor rounded-md shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Get started
              </button>
            </Link>
          </div>
        </div>
      </section>

      <div className="px-0 md:px-4 space-y-8">
        {/* Intro */}
        <section data-aos="fade-up" className="py-16">
          <p className="text-lg text-gray-600 leading-relaxed">
            Accelerating the digital journey requires a digital platforms engine
            to unlock new value. Identify the best way to update your
            organisation’s technology infrastructure, practices, and systems so
            you can stay competitive in an ever-evolving market. With the
            support of Matadigits, you can develop a plan for modernising
            technology to meet user needs and have a partner by your side for
            smooth implementation.
          </p>
        </section>

        {/* Automate your workflows Section */}
        <section className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
          <img
            src={beltImage}
            className="w-full md:w-1/2 rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
            alt="Customer Experience"
          />
          <div data-aos="fade-left">
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
                <FaRobot className="text-3xl text-primaryColor" />
              </div>
              <h2 className="text-xl text-primaryColor ml-3">
                Automate your workflows
              </h2>
            </div>
            <p className="text-lg leading-relaxed">
              <strong>Unstructured Data meets Automated Workflows.</strong>
            </p>
            <p className="text-lg text-gray-600 leading-relaxed">
              Classify, extract to pre & post processing data, and build simple
              workflows for complex tasks.
            </p>
          </div>
        </section>

        {/* Applications & Mobile Development Section */}
        <section className="grid md:grid-cols-2 gap-10 items-center ">
          <div data-aos="fade-right">
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
                <FaMobile className="text-3xl text-primaryColor" />
              </div>
              <h2 className="text-xl text-primaryColor ml-3">
                Applications & Mobile Development
              </h2>
            </div>
            <p className="text-lg leading-relaxed">
              <strong>Build Apps for purpose.</strong>
            </p>
            <p className="text-lg text-gray-600 leading-relaxed">
              Custom software development for desktop, browser, mobile devices,
              kiosks, and embedded devices.
            </p>
          </div>
          <img
            src={mobileImage}
            className="w-full rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
            alt="Data Monetisation"
          />
        </section>

        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaDatabase className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">Data Engineering</h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Turn the data into a goldmine of insights.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Architect, build, manage, and optimise models and infrastructure for
            continuously collecting and managing data. Our data engineering
            experts help gather data from multiple sources, aggregate, combine,
            and transform it into functional data repositories, and expose the
            data via services for others to access. Extract, clean, and
            standardise business data at scale, using Machine Learning models.
          </p>
        </section>

        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaConnectdevelop className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">
              AI & Machine Learning
            </h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Make AI & ML pragmatic.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Gain a deep understanding of how AI/ML and Generative AI can help
            achieve your company goals. These emerging technologies can be
            especially powerful for improving decision-making, automating
            repetitive tasks, and developing personalised customer experiences.
          </p>
        </section>

        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaUserCog className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">Personalisation</h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Real-time personalised data.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Better tags, real-time users action data, and complete profiles of
            your users – when they all come together, AI models start to work on
            a first-name basis. Interpret rules uniformly, iterate feedback
            loops instantly and label data consistently - without human
            moderators. Eliminate the pinch points. Maximise lead generation and
            conversion through data-driven decision-making for campaign
            management and personalised lead targeting.
          </p>
        </section>
        {/* Call to Action Section */}
        <section
          data-aos="fade-up"
          className="bg-gradient-to-r from-primaryColor to-[#2F4858] py-16 text-white text-center rounded-lg"
        >
          <h2 className="text-3xl mb-4">
            Ready to Transform Your Digital Strategy?
          </h2>
          <p className="text-lg mb-6">
            Partner with us to craft a digital strategy that drives growth,
            innovation, and customer engagement.
          </p>
          <Link to="/contact-us">
            <button className="bg-white text-primaryColor font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition">
              Contact Us
            </button>
          </Link>
        </section>
      </div>
    </div>
  );
};

export default TechnologyModernisationPage;
