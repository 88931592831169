import { useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle";
import useArticleList from "../../utils/useArticleList";
import ArticleGridCard from "../../components/articleGridCard";
import ArticleStackCard from "../../components/articleStackCard";

const ResearchPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { articles, loading, setCurrentPage, currentPage, totalItems } =
    useArticleList(2);

  // Detect screen size to toggle between StackCard and GridCard
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile width as <= 768px
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize); // Clean up the listener
  }, []);

  // Infinite scroll to fetch more articles when reaching the bottom
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position and the height of the document
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      // Check if the user is close to the bottom of the page
      if (scrollTop + clientHeight >= scrollHeight - 200 && !loading) {
        // Load more articles if not loading and there are more articles to fetch
        if (articles.length < totalItems) {
          setCurrentPage(currentPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll); // Add scroll event listener
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup the event listener
  }, [loading, articles, totalItems, currentPage, setCurrentPage]);

  return (
    <div className="mx-4">
      <PageTitle text="Research" />

      {/* Cards Section */}
      <div className="flex flex-wrap mt-10 mx-[-1rem] md:mx-[-2rem]">
        {articles.map((section) =>
          isMobile ? (
            <ArticleGridCard key={section.id} {...section} /> // Render GridCard on mobile
          ) : (
            <ArticleStackCard key={section.id} {...section} /> // Render StackCard on desktop
          )
        )}
      </div>

      {/* Loader */}
      {loading && (
        <div className="flex justify-center items-center my-10">
          <div className="loader"></div> {/* Add your loader style here */}
        </div>
      )}
    </div>
  );
};

export default ResearchPage;
