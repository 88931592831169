import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import controlsImage from "../../assets/images/controls.jpg";
import glassesImage from "../../assets/images/glasses-land.jpg";
import { Link } from "react-router-dom";
import "./styles.css";
import {
  FaDollarSign,
  FaPeopleCarry,
  FaProjectDiagram,
  FaPuzzlePiece,
  FaRocket,
  FaUsers,
} from "react-icons/fa";

const DigitalStrategyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing style
      once: true, // Only animate once when scrolled into view
    });
  }, []);

  return (
    <div className="mx-auto space-y-0 px-10">
      {/* Hero Section */}
      <section className="relative flex flex-col justify-center items-start text-left h-[55vh] bg-gray-50 rounded-3xl shadow-lg overflow-hidden parallaxDigitalStrategy mx-[-1rem] md:mx-[-2rem]">
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/20"></div>
        <div className="absolute top-20 md:top-20 left-4 md:left-20 right-4 md:right-20 z-10">
          <h1 className="text-4xl md:text-7xl text-white drop-shadow-lg mb-4 md:mb-6">
            Digital strategy
          </h1>
        </div>
        <div className="absolute bottom-8 left-4 md:left-20 z-10 w-[90%] md:w-1/3">
          <div className="space-y-4">
            <p className="text-sm md:text-base text-white drop-shadow-md leading-relaxed">
              Let’s engage to help you craft a growth-pragmatic digital
              strategy.
            </p>
            <Link to="/contact-us">
              <button className="px-4 py-2 mt-4 md:mt-10 md:px-6 md:py-2 text-base md:text-lg text-white bg-primaryColor rounded-md shadow-lg hover:bg-primaryColor-dark hover:scale-105 transform transition duration-300 ease-in-out">
                Get started
              </button>
            </Link>
          </div>
        </div>
      </section>

      <div className="px-0 md:px-4 space-y-8">
        {/* Digital Strategy Section */}
        <section data-aos="fade-up" className="py-16">
          <p className="text-lg text-gray-600 leading-relaxed">
            Accelerating the discovery and delivery of new value is what will
            help your organisation gain and sustain a competitive edge in the
            market. Whether you’re a digital native or in the midst of
            transformation, we help you navigate the journey from quick wins to
            big bets so you can thoughtfully drive retention, expansion, and
            value.
          </p>
          <br />
          <p className="text-lg text-gray-600 leading-relaxed">
            Whether you’re a digital native or in the midst of transformation,
            Matadigits partners with your team to align objectives to outcomes,
            crafting holistic strategies and realistic roadmaps to achieve your
            digital vision. Together, we help you navigate the journey from
            quick wins to big bets so you can thoughtfully drive retention,
            expansion, and value.
          </p>
        </section>

        {/* Customer Experience Section */}
        <section className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
          <img
            src={glassesImage}
            className="w-full md:w-1/2 rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
            alt="Customer Experience"
          />
          <div data-aos="fade-left">
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
                <FaUsers className="text-3xl text-primaryColor" />
              </div>
              <h2 className="text-xl text-primaryColor ml-3">
                Customer Experience & Engagement
              </h2>
            </div>
            <p className="text-lg leading-relaxed">
              <strong>Raise the game in customer experience.</strong>
            </p>
            <p className="text-lg text-gray-600 leading-relaxed">
              Elevate customer experience, boost engagement, and drive long-term
              relationships by bringing future-state, omnichannel digital
              experiences to life.
            </p>
          </div>
        </section>

        {/* Data Monetisation Strategy */}
        <section className="grid md:grid-cols-2 gap-10 items-center ">
          <div data-aos="fade-right">
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
                <FaDollarSign className="text-2xl text-primaryColor" />
              </div>
              <h2 className="text-xl text-primaryColor ml-3">
                Data Monetisation Strategy
              </h2>
            </div>
            <p className="text-lg leading-relaxed">
              <strong>Data is gold. Mine it effectively.</strong>
            </p>
            <p className="text-lg text-gray-600 leading-relaxed">
              Transform your data into tangible business value with our
              expert-driven strategy, optimized for your enterprise’s needs.
            </p>
          </div>
          <img
            src={controlsImage}
            className="w-full rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
            alt="Data Monetisation"
          />
        </section>

        {/* Product Strategy */}
        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaPeopleCarry className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">Product Strategy</h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Make your products talk for the brand.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Accelerate transformation across and within product lines. Our teams
            partner with yours to define strategies that capture today’s market
            in awareness of existing assets, time to market, cost of ownership,
            and delivery capabilities.
          </p>
        </section>

        {/* Growth Strategy */}
        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaPuzzlePiece className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">Growth Strategy</h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Growth is what counts.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Plan the measures your team will take to expand your brand adoption,
            customer base, market share, and revenue streams.
          </p>
        </section>

        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaProjectDiagram className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">
              User & Market Insights
            </h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Market & user intelligence is a new science.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Gather data and insights and turn them into actionable plans. Our
            strategy and data science teams leverage quantitative, qualitative,
            and algorithmic techniques to uncover market and audience insights,
            inform segmentation, and connect behavioural patterns directly to
            the product backlog.
          </p>
        </section>

        <section data-aos="fade-up">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center h-12 w-12 rounded-full bg-primaryColor/10">
              <FaRocket className="text-3xl text-primaryColor" />
            </div>
            <h2 className="text-xl text-primaryColor ml-3">
              Innovation Strategy
            </h2>
          </div>
          <p className="text-lg leading-relaxed">
            <strong>Innovation is a perpetual game.</strong>
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
            Partner with our team to assess transformation readiness, determine
            innovation models best suited to the realities of your business,
            upskill employees, and help establish innovation as an ongoing
            practice.
          </p>
        </section>

        {/* Call to Action Section */}
        <section
          data-aos="fade-up"
          className="bg-gradient-to-r from-primaryColor to-[#2F4858] py-16 text-white text-center rounded-lg"
        >
          <h2 className="text-3xl mb-4">
            Ready to Transform Your Digital Strategy?
          </h2>
          <p className="text-lg mb-6">
            Partner with us to craft a digital strategy that drives growth,
            innovation, and customer engagement.
          </p>
          <Link to="/contact-us">
            <button className="bg-white text-primaryColor font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition">
              Contact Us
            </button>
          </Link>
        </section>
      </div>
    </div>
  );
};

export default DigitalStrategyPage;
